<template>
  <div class="message-input-container">
    <form class="message-input-container" @submit.prevent="send_message">
      <input
        type="text"
        id="message-input"
        placeholder="Schreibe deine Frage"
        v-model.trim="current_input"
        :class="{ 'input_animated': input_animated }"
        enterkeyhint="send"
      />
    </form>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 26"
      @click="send_message"
      :class="{ 'send-button': true }"
    >
      <path d="M0 0h24v24H0z" />
      <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
    </svg>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      current_input: "",
      input_animated: false,
      input_respawn: false,
    };
  },

  methods: {
    ...mapActions(["perform_query"]),
    ...mapMutations(["add_message"]),

    send_message() {
      if (this.current_input.length > 0) {
        this.add_message({ string: this.current_input, in: false });
        this.perform_query({nextState: null, data:this.current_input});

        this.current_input = "";
        this.animate_button();
      } else {
        this.animate_input();
      }
    },
    async animate_button() {
      this.input_respawn = false;
      await this.Sleep(1000);
      this.input_respawn = true;

    },
    async animate_input() {
      this.input_animated = true;
      await this.Sleep(1000);
      this.input_animated = false;
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
  },
};
</script>

<style scoped>

.message-input-container {
  width: 100%;
  border: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width-top: 2px;
  border-color: lightgrey;
  background: white;
  display: flex;
  flex-direction: row;
  height: calc(45px + calc(1.1 * var(--input-font-size)));
  min-height: calc(45px + calc(1.1 * var(--input-font-size)));
  align-content: center;
}

.input_animated{
  animation: headShake;
  animation-duration: 0.5s;
}

.send-button {
  height: 1.7em;
  font-size: 1.7em;
  margin-top: 15px;
  margin-bottom: 15px;

  margin-right: 0.5em;
  cursor: pointer;
  align-self: center;
  transition: 1s fill;
  animation: fadeIn;
  animation-duration: 1s;
  fill: var(--bubble-color);
}

#message-input {
  flex-grow: 1;
  width: 90%;
  padding-left: 2em;
  border: 0;
  background-color: var(--lighter-bg-color);
  font-size: var(--input-font-size);
  border-bottom-left-radius: 10px;
  vertical-align: middle;
}
@media (pointer: fine) {
  .send-button:hover {  
  fill: var(--button-hover);
  transition: 1s fill;
}
}
</style>
