<template>

  <div class="chat">
    <ChatHeader/>
    <div id="messagge-window" class="messagge-window">
      <ChatMessage
        v-for="(message, index) in chatmessages"
        :key="index"
        :message="message"
        :message_index="index"
      />
    </div>
    <div id="chat_input_container">
      <ChatInput/>
      
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import ChatMessage from "./ChatMessage.vue";
import ChatInput from "./ChatInput.vue";
import ChatHeader from "./ChatHeader.vue";


function mobileCheck() {
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export default {

  components: {
    ChatMessage,
    ChatInput,
    ChatHeader
  },
  methods: {
    ...mapActions(["perform_query"]),
    ...mapMutations(["add_message", "get_config", "update_current_api_link", "update_chatid", "set_config", "set_mobile"]),
    ...mapGetters(["get_css_vars", "get_mobile"]),
   
    get_config(chatbot_id) {
      return new Promise((resolove) => {
        fetch("https://api.chat-q.de:420/chatbot?chatbot_id=" + chatbot_id)
          .then((response) => response.json())
          .then((data) => {
            resolove(data);
          })
          .catch((err) => console.log(err));
      });
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },

  },
  computed: {
    chatmessages: {
      get() {
        return this.$store.state.chatmessages;
      },
    },
    config: {
      get() {
        return this.$store.state.config;
      },
    },
  },
  beforeCreate() {
    this.$store.commit('set_mobile', mobileCheck())
  },
  async mounted() {
    var r = document.querySelector(":root");
    await this.Sleep(10);
    var data = await this.get_config(
      this.$router.currentRoute._value.query.chatid
    );
    this.set_config(data)
    Object.keys(data).forEach((key) => {
      if (key.substring(0, 2) == "--") {
        r.style.setProperty(key, data[key]);
      }
    });
    this.update_current_api_link(data.bot_link);



    function makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
    this.update_chatid(makeid(64));


    if (this.$store.state.mobile) {
      r.style.setProperty("--font-size", "var(--font-size-mobile)");
      r.style.setProperty("--text-size", "var(--text-size-mobile)");
      r.style.setProperty("--input-font-size", "var(--input-font-size-mobile)");
    } else {
      //desktop
    }
  },
};
</script>

<style scoped>
.chat {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: 100%;
  background: #eeeeee;
  border: solid;
  border-color: lightgrey;
  border-width: 1px !important;
  border-radius: 10px;
  overflow-x: hidden;
}


.messagge-window {
  flex-grow: 1;
  background-image: var(--background-image);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-bottom: solid;
  border-width: 1px !important;
  border-color: lightgrey;
  /*box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19);*/
}
/* Hide scrollbar for Chrome, Safari and Opera */
.messagge-window::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.messagge-window {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: hidden;

}

.list-enter-active,
.list-leave-active {
  opacity: 1;
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}



#chat_input_container{
  display: unset;
  display: var(--display_text_input)
}
</style>
