<template>
  <div class="message-container">
    <div :class="{ message: true, in: message.in, out: !message.in }">
      <div v-if="'image' in message">
        <img :src="message.image" alt="" srcset="" />
      </div>
      <p class="message_text" v-html="reformatMessage(message.string)"></p>

      <div v-if="'textInput' in message" class="message-input-outer-container">
        <br />
        <form
          :class="{
            input_animated: input_animated,
            'message-input-container': !sent,
            'message-input-container-sent': sent,
          }"
          @submit.prevent="send_message_textInput"
        >
          <input
            v-if="!sent"
            id="textInputField"
            :class="{ 'text-input': !sent, 'text-input-sent': sent }"
            v-model.trim="textInputContent"
            enterkeyhint="send"
            @keyup.enter="send_message_textInput()"
            placeholder="Hier eingeben..."
          />

          <div v-if="sent" :class="{ 'textInput-textfield': true }">
            {{ textInputContent }}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 23 26"
            @click="send_message_textInput"
            :class="{
              'send-button': !sent,
              'sendable-button': isSendable,
              'send-button-sent': sent,
            }"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
          </svg>
        </form>
      </div>

      <div v-if="'options' in message">
        <br />
        <ChatButtonList
          :key="message.options"
          :message_index="message_index"
          v-bind="message"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChatButtonList from "@/components/Chat/ChatButtonList";
import { mapActions, mapMutations } from "vuex";
export default {
  components: { ChatButtonList },
  props: ["message", "message_index"],
  data() {
    return {
      input_animated: false,
      textInputContent: "",
      sent: false,
    };
  },
  methods: {
    ...mapActions(["perform_query"]),
    ...mapMutations(["add_message"]),

    reformatMessage(message) {
      if (message.includes("<hyperlink>")) {
        var matchresult = message.match("<hyperlink>\\S*</hyperlink>")[0];
        matchresult = matchresult.replace("<hyperlink>", "");
        matchresult = matchresult.replace("</hyperlink>", "");
        var split = matchresult.split("|||");
        message = message.replace(
          matchresult,
          '<a style="color: var(--button-hover); font-size: bold; text-decoration: none" id="test" href="' +
            split[0] +
            '" target="_blank"><b>' +
            split[1] +
            "</b></a>"
        );
      }
      return message;
    },
    async animate_input() {
      this.input_animated = true;
      await this.Sleep(1000);
      this.input_animated = false;
    },
    send_message_textInput() {
      if (this.isSendable) {
        document.getElementById("textInputField").blur();
        this.sent = true;
        this.perform_query({
          nextState: this.message.nextState,
          data: { varName: this.message.varName, value: this.textInputContent },
        });
      } else {
        this.animate_input();
      }
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
  },
  computed: {
    messageString: function() {
      return this.reformatMessage(this.message.string);
    },

    isSendable: function() {
      if (this.message.textInput === "") {
        return true;
      } else {
        return RegExp(this.message.textInput).test(this.textInputContent);
      }
    },
  },
};
</script>

<style scoped>
.message {
  padding: 0.4em 1em 0.4em 1em;
  background-color: var(--lighter-bg-color);
  border-radius: 10px;
  max-width: var(--max-message-width);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
}
.out {
  margin-left: auto;
  background: var(--bubble-color);
  color: var(--message-out-font-color);

  animation: slideInRight;
  animation-duration: 0.2s;
  width: fit-content;
}

.in {
  animation: slideInLeft;
  animation-duration: 0.2s;
  color: var(--message-in-font-color);
  width: fit-content;
}

.message-input-outer-container {
  float: right;
  width: 100%;
}

.send-button {
  height: 1.5em;
  font-size: 1.5em;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 0.5em;
  cursor: not-allowed;
  align-self: center;
  fill: var(--button-hover);
  fill-opacity: 50%;
  transition: 0.2s fill;
  animation: fadeIn;
  animation-duration: 1s;
}

.send-button-sent {
  height: 1.5em;
  font-size: 1.5em;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 0.5em;
  cursor: not-allowed;
  align-self: center;
  fill: var(--accent-color);
  fill-opacity: 50%;
  transition: 0.2s fill;
  animation: fadeIn;
  animation-duration: 1s;
  visibility: hidden;
}

.sendable-button {
  fill-opacity: 100% !important;
  cursor: pointer;
}

.input_animated {
  animation: headShake;
  animation-duration: 0.5s;
}

img {
  max-width: 100%;
  height: auto;
}

.message_text {
  text-align: left;
}
.message-container {
  margin: 1em 1em 0.8em 1em;
  display: flex;
  flex-direction: column;
}

.message-input-container {
  width: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.1);
  flex-direction: row;
  height: calc(45px + calc(1.1 * var(--input-font-size)));
  min-height: calc(45px + calc(1.1 * var(--input-font-size)));
  align-content: center;
  transition: 0.3s;

  border: transparent;
  border-style: solid;
  border-width: 3px;
  border-radius: 5px;
}

.message-input-container-sent {
  width: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.1);
  flex-direction: row;
  height: calc(45px + calc(1.1 * var(--input-font-size)));
  min-height: calc(45px + calc(1.1 * var(--input-font-size)));
  align-content: center;
  transition: 0.3s;

  border: transparent;
  border-style: solid;
  border-width: 3px;
  border-radius: 5px;
}

.message-input-container:hover {
  border-color: var(--accent-color);
  background: transparent;
}

.message-input-container:focus-within {
  border-color: var(--accent-color);
  background: transparent;
}

.text-input {
  width: 100%;
  padding: 10px;
  min-width: 300px;
  border: 0;
  transition: 0.2s;
  background: transparent;
  color: var(--lighter-bg-color);
}

.text-input-sent {
  width: 100%;
  padding: 10px;
  min-width: 300px;
  border: 0;
  transition: 0.2s;
  background: transparent;
  pointer-events: none;
  font-weight: bold;
}
.textInput-textfield {
  padding: 10px;
  border: 0;
  transition: 0.2s;
  background: transparent;
  pointer-events: none;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (hover: none) and (pointer: coarse) {
  .message {
    max-width: var(--max-message-width-mobile);
  }
}
</style>
