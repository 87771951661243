<template>
  <div class="main_container">
    <Chat />
  </div>
</template>

<script>
import Chat from "./components/Chat/Chat.vue";
import { mapMutations } from "vuex";

export default {
  name: "App",
  components: {
    Chat,
  },
  methods: {
    ...mapMutations(["set_darkmode"]),
    isDarkModeEnabled() {
      return (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      );
    },
  },
  created() {
    this.set_darkmode(this.isDarkModeEnabled());
  },
};
</script>

<style>
* {
  font-size: var(--font-size);
}

p {
  font-size: var(--text-size);
}
body {
  line-height: 1.4;
  background-color: var(--bg-color);
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  color: var(--font-color);
  display: flex;
  flex-direction: row;
}
:root {
  --primary-color: rgb(132, 170, 167);
  --secondary-color: rgb(132, 170, 167);
  --highlight-color: #c0110b;

  --text-on-lighter-bg: #424242;
  --text-on-bg: #424242;
}

[data-theme="dark"] {
}

.content {
  margin: 0 0 0 var(--sidebar-width);
  height: 100%;
  padding: 0px;
  flex-grow: 1;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 700px) {
  div.content {
    margin-left: 0;
  }
  #app {
    flex-direction: column;
  }
}
*:focus {
  outline: none;
}
.main_container {
  height: inherit;
  width: 100%;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
