<template>
  <div class="button-list">
    <button
      :id="option_index + message_index * 100"
      :style="cssVars"
      v-on:click="clickButton"
      :class="{
        clickable: is_clickable,
        selected: selected_strings.indexOf(option.answer) != -1,
        message_button: true,
      }"
      :query="'value' in option ? option.value : option.answer"
      :nextState="option.nextState"
      v-for="(option, option_index) in options"
      :key="option_index + message_index * 10"
    >
      {{ option.answer }}
    </button>
    <form
      class="custom_input_container"
      v-if="typeof custom_text_addable !== 'undefined'"
    >
      <label>{{ custom_text_addable.label }}</label>
      <input
        :class="{ add_custom_button: true, not_clickable: !is_clickable }"
        type="text"
        :placeholder="custom_text_addable.placeholder"
        @focus="add_textfeld"
        v-for="(item, idx) in custom_inputs"
        :key="idx"
        v-model="custom_inputs[idx]"
      />
    </form>
  </div>

  <div id="button_select_footer">
    <div
      id="button_select_message"
      :class="{ text_hint_animated: text_hint_animated }"
    >
      <p
        v-if="
          max_select > 0 &&
            selected_options.length == 0 &&
            max_select == min_select &&
            is_clickable
        "
      >
        Hier musst du {{ min_select }} Optionen auswählen!
      </p>
      <p
        v-if="
          max_select > 0 &&
            selected_options.length == 0 &&
            max_select != min_select
        "
      >
        Hier kannst du bis zu {{ max_select }} Optionen auswählen!
      </p>

      <p
        v-if="
          max_select > 0 &&
            selected_options.length > 0 &&
            max_select != selected_options.length &&
            is_clickable
        "
      >
        Du hast bereits {{ selected_options.length }} von
        {{ max_select }} Optionen ausgewählt!
      </p>
      <p v-if="!is_clickable"><b>Vielen Dank!</b></p>
      <p
        v-if="
          max_select == selected_options.length && !auto_submit && is_clickable
        "
      >
        <b>Perfekt! Jetzt nur noch absenden ➡️</b>
      </p>
    </div>
    <div v-if="!auto_submit">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23 26"
        @click="confirm_options"
        :class="{ 'send-button': true, send_animate: true, not_clickable: !is_clickable }"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "Buttons",
  props: [
    "options",
    "n_cols",
    "message_index",
    "min_select",
    "max_select",
    "auto_submit",
    "nextState",
    "varName",
    "custom_text_addable",

    "has_image",
    "in",
    "string",
  ],
  data() {
    return {
      is_clickable: true,
      selected_options: [],
      selected_strings: [],
      text_hint_animated: false,
      custom_inputs: [""],
    };
  },
  methods: {
    ...mapActions(["perform_query"]),
    ...mapMutations(["add_message"]),

    add_textfeld() {
      let empty_count = 0;
      if (this.is_clickable) {
        for (var i = this.custom_inputs.length - 1; i > 0; i--) {
          if (this.custom_inputs[i].trim() == "") {
            empty_count += 1;
            if (empty_count > 1) {
              this.custom_inputs.splice(i, 1);
            }
          }
        }
        this.custom_inputs.push("");
      }
    },

    clickButton(event) {
      if (this.is_clickable) {
        let node_value = event.target.attributes.query.nodeValue;
        let node_string = event.target.innerText;

        let selected_index = this.selected_options.indexOf(node_value);
        if (this.selected_options.length == this.max_select) {
          this.animate_text_hint();
        }

        // if the element is already selected and its clicked to deselect it
        if (selected_index != -1) {
          this.selected_options.splice(selected_index, 1);
          this.selected_strings.splice(selected_index, 1);

          // Die selected Klasse von dem Objekt entfernen
          // event.target.className = event.target.className.split(' selected').join('')
        }

        // Select a new Value
        else if (
          this.max_select == -1 ||
          this.selected_options.length < this.max_select
        ) {
          // event.target.className += ' selected';
          this.selected_options.push(node_value);
          this.selected_strings.push(node_string);
        }
        if (this.selected_options.length == this.max_select) {
          if (this.auto_submit) {
            this.is_clickable = false;

            let answer_params = {
              varName: this.varName,
              value: this.selected_options,
            };

            this.perform_query({
              nextState: event.target.attributes.nextState.nodeValue,
              data: answer_params,
            });
            this.send_button_selection();
          }
        }
      }
    },

    confirm_options() {
      if (this.selected_options.length >= this.min_select && this.is_clickable) {
        for (var i = this.custom_inputs.length - 1; i > 0; i--) {
          if (this.custom_inputs[i].trim() == "") {
            this.custom_inputs.splice(i, 1);
          }
        }
        this.is_clickable = false;
        let all_active_options = [
          ...this.selected_options,
          ...this.custom_inputs,
        ];
        this.selected_strings = [
          ...this.selected_strings,
          ...this.custom_inputs,
        ];
        let answer_params = {
          varName: this.varName,
          value: all_active_options,
        };
        this.send_button_selection();
        this.perform_query({
          nextState: this.options[0].nextState,
          data: answer_params,
        });
      } else {
        this.animate_text_hint();
      }
    },

    send_button_selection() {
      if (this.$store.state.config.user_send_answer_on_button_click) {
        this.selected_strings.forEach((selected_item) => {
          if (selected_item != "") {
            this.add_message({
              string: selected_item,
              in: false,
            });
          }
        });
      }
    },

    async animate_text_hint() {
      this.text_hint_animated = true;
      await this.Sleep(1000);
      this.text_hint_animated = false;
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
  },
  computed: {
    cssVars() {
      if (this.$store.state.mobile) {
        return { "--width": "90%" };
      }
      return {
        "--width": 90 / this.n_cols + "%",
      };
    },
  },
};
</script>

<style scoped>
.button-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

button {
  margin: 2px;
  padding: 5px;
  width: var(--width);
  min-width: var(--width);
  border-radius: 7px;
  border-width: 1px !important;
  border: solid;
  border-color: lightgrey;
  background: var(--button-not-clickable);
  font-size: var(--text-size);
  color: var(--font-color);

  transition: color 0.1s;
}
button.clickable {
  margin: 2px;
  padding: 5px;
  border-radius: 7px;
  border: 1px solid lightgray !important;
  background: white;
  cursor: pointer;
}

button.selected {
  background: var(--button-selected);
  color: var(--font-color-button-selected);
}

@media (pointer: fine) {
  button.clickable:hover {
    background: var(--button-hover);
    color: var(--font-color-button-selected);
  }
}
#button_select_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

#button_select_message {
  height: 1.5em;
  align-self: center;
}

.send-button {
  height: 1.5em;
  font-size: 1.5em;

  cursor: pointer;
  fill: var(--button-hover);
  transition: 0.6s fill;
  animation: fadeIn;
  animation-duration: 1s;
}
.send-button:hover {
  fill: var(--button-selected);
}

.text_hint_animated {
  animation: headShake;
  animation-duration: 0.5s;
}
p {
  display: none;
}

.add_custom_button {
  width: 100%;
  border: 2 var(--bubble-color);
  transition: 0.2s;
  background: var(--bubble-color);
  border-radius: 7px;
  color: white;
  font-size: var(--text-size);
  padding: 7px;
  transition: 0.6s background;
}

.custom_input_container {
  margin-top: 0.5em;
  margin-bottom: 0.2em;
  width: 90%;
}
.add_custom_button {
  margin-bottom: 0.3em;
}

input.not_clickable {
  background: var(--button-not-clickable);
  color: var(--font-color);
}

svg.not_clickable {
  fill: var(--button-not-clickable);
}
svg.not_clickable:hover {
  fill: var(--button-not-clickable);
  cursor: no-drop;
}
</style>
