import { createRouter, createWebHistory } from 'vue-router'

import Chat from "../components/Chat/Chat.vue";

const routes = [
  {
    path: '/',
    name: 'Chat',
    component: Chat,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
