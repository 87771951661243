import { createStore } from "vuex";

export default createStore({
  state: {
    darkmode: true,
    mobile: false,
    chatmessages: [
      
    ],
    config: Object(),
    current_api_link: "",
    chatid: "",
    config_api_link:
      "https://api.chat-q.de:420/chatbot?chatbot_id=/chatbot?chatbot_id=",
    css_vars: Object(),
  },
  mutations: {
    set_darkmode(state, isEnabled) {
      if (isEnabled) {
        document.documentElement.setAttribute("data-theme", "dark");
      } else {
        document.documentElement.setAttribute("data-theme", "light");
      }
      state.darkmode = isEnabled;
    },
    change_darkmode(state) {
      this.commit("set_darkmode", !state.darkmode);
    },
    add_messages(state, messages) {
      for (var i = 0; i < messages[0].length; i++) {
        setTimeout(
          function(messages, i) {
            messages[i].in = true;
            if(typeof messages[i].textInput != "undefined") {
              messages[i].in = false;
            }
            messages[i].has_image = "image" in messages[i];
            state.chatmessages.push(messages[i]);
            setTimeout(function() {
              const element = document.getElementById("messagge-window");
              if (element != null) {
                element.scrollTop = element.scrollHeight;
              }
            }, 100);
          },
          i * 1000,
          messages[0],
          i
        );
      }

    },

    add_message(state, message) {
      state.chatmessages.push(message);
    },
    remove_last_message(state) {
      state.chatmessages.pop();
    },
    update_current_api_link(state, value) {
      state.current_api_link = value;
    },
    update_chatid(state, value) {
      state.chatid = value;
    },
    set_config(state, config) {
      state.config = config;
      if ("init_messages" in config){
        this.commit("add_messages", [config["init_messages"]])
      }
    },
    set_mobile(state, mobile) {
      state.mobile = mobile;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    perform_query(state, {nextState, data}) {

      var postbody = {
        "chatid": this.state.chatid,
      }

      if (nextState !== null){
        postbody["nextState"] = nextState.trim()
      }

      if(typeof data.varName !== "undefined") {
        postbody.varName = data.varName;
        postbody.value = data.value;
      }

      if(typeof data != "object") {
        postbody.msg = data;
      }

      fetch(this.state.current_api_link, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postbody)
      }).then((response) => response.json())
        .then((data) => this.commit("add_messages", data))
        .catch((err) => console.log(err));
    },
  },
  modules: {},
  getters: {
    get_chatmessages: (state) => state.chatmessages,
    get_config: (state) => state.config,
    get_mobile: (state) => state.mobile,
  },
});
