<template>
  <div id="header">
    <img id="profilbild" :src="config.profilbild" alt="Unternehmenslogo" />
    <h1 id="bot_name">{{ config.header_headline }}</h1>
  </div>
</template>

<script>
export default {
  computed: {
    config: {
      get() {
        return this.$store.state.config;
      },
    },
  },
};
</script>

<style scoped>
#header {
  height: calc(45px + calc(1.1 * var(--input-font-size)));
  min-height: calc(45px + calc(1.1 * var(--input-font-size)));
  background-color: rgb(243, 243, 243);
  box-shadow: 5px 10px #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px lightgray solid;
  display: var(--display-header);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#profilbild {
  height: 80%;
  aspect-ratio: 1;
  align-self: center;
  margin-left: 0.8em;
  border-radius: 1000000%;
  border-radius: var(--profile_pic_border_radius);
}

#bot_name {
  vertical-align: middle;
  align-self: center;
  margin-left: 0.8em;
  font-size: 1.4em;
}

</style>
